import moment from 'moment'

function formatarDataSemHora (data) {
  if(!data) return
  const formato = 'DD/MM/YYYY'
  return moment(data).format(formato)
}

function formatarDataComHora (data) {
  if(!data) return
  const formato = "DD/MM/YYYY [às] HH:mm[h]"
  return moment(data).format(formato)
}

function hoje () {
  return new Date()
}

function formatarDataParaUSA (data) {
  if(!data) return
  const formato = "YYYY-MM-DD"
  return moment(data).format(formato)
}

export default {
  formatarDataSemHora,
  formatarDataComHora,
  hoje,
  formatarDataParaUSA
}

