import { defineStore } from "pinia"
import { useStoreAlerta } from "./index"
import apiUsuarios from "../api/unicpages/api-usuarios"

export const useStoreUsuarios = defineStore('storeUsuarios', {
  state: () => {
    return {
      usuarios: [],
      usuario: [],
      usuariosBackup: [],
      modals: {
        idUsuario: null,
        email: null,
        imagemPerfil: null,
      },
      mostrarModalAcesso: false,
      mostrarModalResetarSenha: false,
      mostrarModalSites: false,
      mostrarModalBloquearAcesso: false
    }
  },
  actions: {
    async receberTodos(){
      try {
        const resp = await apiUsuarios.receberUsuarios()
        this.usuarios = resp.data.body
        return true
      }
      catch (error) {
        this.usuarios = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberUsuarioPorId(idUsuario){
      try {
        const resp = await apiUsuarios.receberUsuarioPorId(idUsuario)
        this.usuario = resp.data.body
        return true
      }
      catch (error) {
        this.usuario = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async resetarSenhaUsuario(idUsuario){
      try {
        const resp = await apiUsuarios.resetarSenhaUsuario(idUsuario)
        useStoreAlerta().exibirSucessoRequest(resp.data.body)
        return true
      }
      catch (error) {
        this.usuario = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

  }
})
