<template>
  <section class="dados">
    <div class="principal">
      <Svgs nome="usuario" />
      <h2>{{ contarUsuarios }} usuários</h2>
      <p>No total a UnicPages tem {{ contarUsuarios }} usuários cadastrados</p>
    </div>
    <!-- Acesso Liberado -->
    <div class="titulo liberado">
      <div class="icone">
        <Svgs nome="check" />
      </div>
      <h2>{{ contarUsuariosLiberados }} usuários</h2>
      <p>Usuários com acesso liberado na UnicPages</p>
    </div>
    <!-- Lista -->
    <div class="quadros liberado">
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-free.svg" />
        <h3>{{ contarUsuariosFree }}</h3>
        <p>Plano Free</p>
        <h4>1 site</h4>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-silver.svg" />
        <h3>{{ contarUsuariosSilver }}</h3>
        <p>Plano Silver</p>
        <h4>3 sites</h4>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-gold.svg" />
        <h3>{{ contarUsuariosGold }}</h3>
        <p>Plano Gold</p>
        <h4>10 sites</h4>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-diamond.svg" />
        <h3>{{ contarUsuariosDiamond }}</h3>
        <p>Plano Diamond</p>
        <h4>50 sites</h4>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-unlimited.svg" />
        <h3>{{ contarUsuariosUnlimited }}</h3>
        <p>Plano Unlimited</p>
        <h4>1000 sites</h4>
      </div>
    </div>
    <!-- Acesso bloqueado -->
    <div class="titulo bloqueado">
      <div class="icone">
        <Svgs nome="x" />
      </div>
      <h2>{{ contarUsuariosBloqueados }} usuários</h2>
      <p>Usuários com acesso bloqueado na UnicPages</p>
    </div>
    <!-- Lista -->
    <div class="quadros bloqueado">
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-silver.svg" />
        <h3>{{ contarUsuariosSilverBloqueado }}</h3>
        <p>Plano Silver</p>
        <h4>3 sites</h4>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-gold.svg" />
        <h3>{{ contarUsuariosGoldBloqueado }}</h3>
        <p>Plano Gold</p>
        <h4>10 sites</h4>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-diamond.svg" />
        <h3>{{ contarUsuariosDiamondBloqueado }}</h3>
        <p>Plano Diamond</p>
        <h4>50 sites</h4>
      </div>
      <div class="quadro">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-unlimited.svg" />
        <h3>{{ contarUsuariosUnlimitedBloqueado }}</h3>
        <p>Plano Unlimited</p>
        <h4>1000 sites</h4>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreUsuarios } from '@stores'
import Svgs from '@svgs'

const storeUsuarios = useStoreUsuarios()

const contarUsuariosLiberados = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.ativo).length
})

const contarUsuarios = computed(() => {
  return storeUsuarios.usuarios.length
})

const contarUsuariosFree = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'free' && usuario.assinatura?.ativo).length
})

const contarUsuariosSilver = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'silver' && usuario.assinatura?.ativo).length
})

const contarUsuariosGold = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'gold' && usuario.assinatura?.ativo).length
})

const contarUsuariosDiamond = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'diamond' && usuario.assinatura?.ativo).length
})

const contarUsuariosUnlimited = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'unlimited' && usuario.assinatura?.ativo).length
})

const contarUsuariosBloqueados = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => !usuario.assinatura?.ativo).length
})

const contarUsuariosSilverBloqueado = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'silver' && !usuario.assinatura?.ativo).length
})

const contarUsuariosGoldBloqueado = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'gold' && !usuario.assinatura?.ativo).length
})

const contarUsuariosDiamondBloqueado = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'diamond' && !usuario.assinatura?.ativo).length
})

const contarUsuariosUnlimitedBloqueado = computed(() => {
  return storeUsuarios.usuarios.filter((usuario) => usuario.assinatura?.tipo?.toLowerCase() === 'unlimited' && !usuario.assinatura?.ativo).length
})
</script>

<style scoped>
section.dados {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 20px 0 20px;
  min-height: 100dvh;
}

.principal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--cor-cinza-2);
  width: 100%;
  margin: 0 0 20px 0;
}

.principal svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
}

.principal h2 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 10px 0 15px;
}

.principal p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}

.titulo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 20px 0;
}

.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
}

.titulo .icone svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
}

.titulo.liberado .icone {
  background-color: var(--cor-verde);
}

.titulo.bloqueado .icone {
  background-color: var(--cor-vermelho);
}

.titulo h2 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  margin: 0 10px 0 15px;
}

.titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}

.quadros {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid var(--cor-cinza-4);
  border-radius: 10px;
  margin: 0 0 40px 0;
}

.quadros.bloqueado .quadro {
  width: 25%;
}

.quadro:nth-child(1) {
  border-left: none;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  border-left: 1px solid var(--cor-cinza-4);
  padding: 80px 20px;
}

.quadro img {
  max-height: 30px;
  min-height: 30px;
}

.quadro h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  margin: 30px 0 0 0;
}

.quadro p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 10px 0 0 0;
}

.quadro h4 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  section.dados {
    padding: 20px;
  }

  .principal {
    flex-direction: column;
    padding: 40px 20px;
    margin: 0 0 30px 0;
  }

  .principal svg {
    width: 20px;
    min-width: 20px;
  }

  .principal h2 {
    font-size: var(--f3);
    margin: 15px 0 5px 0;
  }

  .principal p {
    width: 100%;
    max-width: 230px;
    line-height: 1.5;
    text-align: center;
  }

  .titulo {
    flex-direction: column;
    align-items: flex-start;
  }

  .titulo h2 {
    margin: 15px 0 5px 0;
  }

  .quadros {
    justify-content: flex-start;
    padding: 20px;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .quadros::-webkit-scrollbar {
    display: none;
  }

  .quadro {
    width: 190px;
    min-width: 190px;
    padding: 30px 15px;
    margin: 0 20px 0 0;
  }

  .quadro img {
    max-width: 25px;
  }
}
</style>
