<template>
  <div id="usuarios">
    <ModalAcesso />
    <ModalResetarSenha />
    <ModalSites />
    <ModalBloquearAcesso />
    <SectionLista />
    <Footer />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStorePerfil } from '@stores'
import ModalAcesso from './modals/ModalAcesso.vue'
import ModalResetarSenha from './modals/ModalResetarSenha.vue'
import SectionLista from './partials/SectionLista.vue'
import Footer from '@/components/global/footer/Footer.vue'
import ModalSites from './modals/ModalSites.vue'
import ModalBloquearAcesso from './modals/ModalBloquearAcesso.vue'

const storePerfil = useStorePerfil()

function carregarPerfil() {
  storePerfil.receberPerfilAdministrador()
}

onMounted(() => {
  carregarPerfil()
})
</script>

<style scoped>
#usuarios {
  padding: 60px 0 0 0;
}
</style>
