<template>
  <div id="dados">
    <SectionDados />
    <Footer />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStorePerfil, useStoreUsuarios } from '@stores'
import Footer from '@/components/global/footer/Footer.vue'
import SectionDados from './partials/SectionDados.vue'

const storePerfil = useStorePerfil()
const storeUsuarios = useStoreUsuarios()

function carregarPerfil() {
  storePerfil.receberPerfilAdministrador()
}

function carregarUsuarios() {
  storeUsuarios.receberTodos()
}

onMounted(() => {
  carregarPerfil()
  carregarUsuarios()
})
</script>

<style scoped>
#dados {
  padding: 60px 0 0 0;
}
</style>
