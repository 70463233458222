import { api } from './axios'
import { helperToken } from '@helpers'

const token = helperToken.receberToken()

export default {
  receberPorUsuario(idUsuario) {
    if (!idUsuario) return

    const method = 'GET'
    const url = `pages/${idUsuario}`

    return api(method, url, null, token)
  },

  receberAtivos() {
    const method = 'GET'
    const url = 'pages'

    return api(method, url, null, token)
  },

  transferir(idUsuario, idPage, payload) {
    if (!idUsuario && !idPage) return

    const method = 'PATCH'
    const url = `pages/transferir/${idUsuario}/${idPage}`

    return api(method, url, payload, token)
  }
}
