function redirecionar (url, mesmaAba = false) {
  const a = document.createElement('a')
  a.href = url
  if (mesmaAba === false) a.target = '_blank'
  a.click()
}

export default {
  redirecionar
}
