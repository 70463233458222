import { defineStore } from 'pinia'
import apiAutenticacao from '../api/unicpages/api-autenticacao'

export const useStoreLogin = defineStore('storeLogin', {
  state: () => ({
    modalEsqueceuSenhaAberto: false
  }),
  actions: {
    async recuperarSenha(payload) {
      return await apiAutenticacao.recuperar(payload)
    }
  }
})
