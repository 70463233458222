<template>
  <Modal nome="editarPerfil">
    <label class="um">
      Nome
      <span>*</span>
    </label>
    <input type="text" spellcheck="false" placeholder="Nome aqui..." autocomplete="name" v-model="state.nome" />
    <label>
      Foto de Perfil
      <span>*</span>
    </label>
    <div class="inputImagem" :class="{ carregar: state.loading }" @click="abrirInputFile">
      <span></span>
      <div class="previa" :style="`background-image: url('${state.fotoPerfil}')`">
        <Svgs v-if="!state.fotoPerfil" nome="arquivo" />
      </div>
      <div class="texto">
        <h3>{{ state.nomeArquivo || 'Subir Imagem' }}</h3>
        <p>{{ state.tamanhoArquivo || 'Clique aqui e suba a imagem da sua foto' }}</p>
      </div>
      <input type="file" accept=".jpg,.jpeg,.png" hidden @change="selecionarImagem" ref="inputFile" />
    </div>

    <BotaoSalvar nome="Salvar perfil" @click="salvar" />
  </Modal>
</template>

<script setup>
import { reactive, onMounted, inject, ref, computed } from 'vue'
import { useStorePerfil, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import Svgs from '@svgs'
import ApiUpload from '@/api/upload/api-upload.js'

const emitter = inject('emitter')
const storePerfil = useStorePerfil()
const storeModal = useStoreModal()

const inputFile = ref(null)

const state = reactive({
  nome: '',
  fotoPerfil: '',
  nomeArquivo: '',
  tamanhoArquivo: '',
  loading: false
})

function abrirInputFile() {
  inputFile.value.click()
}

function selecionarImagem(arquivo) {
  const arquivoSelecionado = arquivo.target.files[0]
  state.loading = true

  const payload = {
    arquivo: arquivoSelecionado,
    maxWidth: 300
  }

  ApiUpload.uploadImagem(payload)
    .then((resp) => {
      state.loading = false
      state.fotoPerfil = resp?.url
      state.nomeArquivo = resp?.nome
      state.tamanhoArquivo = resp?.peso
    })
    .catch(() => {
      state.loading = false
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao subir imagem de perfil' })
    })
}

async function salvar() {
  const payload = {
    nome: state.nome,
    imagemPerfil: state.fotoPerfil,
    contato: {
      whatsapp: state.whatsapp
    }
  }

  if (state.nome || state.whatsapp) await storePerfil.alterarDados(payload)

  await storePerfil.receberPerfilAdministrador()
  emitter.emit('atualizarPerfil')
  storeModal.fecharModal('editarPerfil')
}

async function preencherInputs() {
  await storePerfil.receberPerfilAdministrador()
  state.nome = storePerfil.usuario?.nome
  state.whatsapp = storePerfil.usuario?.contato?.whatsapp
  state.fotoPerfil = storePerfil.usuario?.imagemPerfil
}

function limparCampos() {
  state.nomeArquivo = ''
  state.tamanhoArquivo = ''
  state.fotoPerfil = ''
  state.nome = ''
}

onMounted(() => {
  preencherInputs()
  emitter.on('carregarCampos', preencherInputs)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
label.um {
  margin: 0 0 10px 0;
}

button {
  margin: 20px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .titulo {
    padding: 20px;
  }

  .botao {
    padding: 20px;
  }

  .campos {
    padding: 20px 20px 0 20px;
  }
}
</style>
