import { defineStore } from 'pinia'
import { useStoreAlerta } from './index'
import apiPages from '../api/unicpages/api-pages'

export const useStorePages = defineStore('storePages', {
  state: () => {
    return {
      pagesUsuario: []
    }
  },
  actions: {
    async receberPorUsuario(idUsuario) {
      try {
        const resp = await apiPages.receberPorUsuario(idUsuario)
        this.pagesUsuario = resp.data.body
        return true
      } catch (error) {
        this.pagesUsuario = []
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberAtivos() {
      try {
        const resp = await apiPages.receberAtivos()
        this.pages = resp.data.body
        return true
      } catch (error) {
        this.pagesUsuario = []
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async transferir(idUsuario, idPage, payload) {
      try {
        const resp = await apiPages.transferir(idUsuario, idPage, payload)
        return useStoreAlerta().exibirSucessoRequest(resp.data.body)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
