<template>
  <section class="modal" :class="{ mostrar: mostrarModalAcesso }">
    <div class="conteudo">
      <div class="titulo">
        <div class="foto" :style="`background-image: url('${storeUsuarios.modals.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'}')`"></div>
        <div class="texto">
          <h3>Acesso do usuário</h3>
          <p>{{ storeUsuarios.modals.email }}</p>
        </div>
        <button @click="fecharModal">
          <span class="um"></span>
          <span class="dois"></span>
        </button>
      </div>

      <div class="opcoes">
        <div class="nome">
          <h3>Plano</h3>
          <h3 class="sites">Sites</h3>
        </div>
        <button @click="selecionarPlano('free')" :class="{ ativo: state.planoSelecionado === 'free' }">
          <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-free.svg" />
          <div class="texto">
            <h3>Free</h3>
            <p>Pode publicar, sem domínio e integrações</p>
          </div>
          <div class="dados">
            <h4>1</h4>
          </div>
        </button>
        <button @click="selecionarPlano('silver')" :class="{ ativo: state.planoSelecionado === 'silver' }">
          <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-silver.svg" />
          <div class="texto">
            <h3>Silver</h3>
            <p>Pode publicar, domínio e integrações</p>
          </div>
          <div class="dados">
            <h4>3</h4>
          </div>
        </button>
        <button @click="selecionarPlano('gold')" :class="{ ativo: state.planoSelecionado === 'gold' }">
          <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-gold.svg" />
          <div class="texto">
            <h3>Gold</h3>
            <p>Pode publicar, domínio e integrações</p>
          </div>
          <div class="dados">
            <h4>10</h4>
          </div>
        </button>
        <button @click="selecionarPlano('diamond')" :class="{ ativo: state.planoSelecionado === 'diamond' }">
          <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-diamond.svg" />
          <div class="texto">
            <h3>Diamond</h3>
            <p>Pode publicar, domínio e integrações</p>
          </div>
          <div class="dados">
            <h4>50</h4>
          </div>
        </button>
        <button @click="selecionarPlano('unlimited')" :class="{ ativo: state.planoSelecionado === 'unlimited' }">
          <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-unlimited.svg" />
          <div class="texto">
            <h3>Unlimited</h3>
            <p>Pode publicar, domínio e integrações</p>
          </div>
          <div class="dados">
            <h4>1000</h4>
          </div>
        </button>
      </div>

      <div class="tempo">
        <h3>Tempo de acesso</h3>
        <div class="itens">
          <button @click="selecionarTempo('mensal')" :class="{ ativo: state.tempoSelecionado === 'mensal' }">
            <p>Mensal (30 dias)</p>
          </button>
          <button @click="selecionarTempo('anual')" :class="{ ativo: state.tempoSelecionado === 'anual' }">
            <p>Anual (365 dias)</p>
          </button>
        </div>
      </div>

      <div class="data">
        <h3>Início do plano</h3>
        <input type="date" v-model="state.dataInicioPlano" />
      </div>

      <div class="botao">
        <button class="salvar" @click="ativarAssinatura">
          Salvar acesso do usuário
          <span></span>
        </button>
      </div>
    </div>
  </section>
</template>
<script setup>
import { reactive, inject } from 'vue'
import { storeToRefs } from 'pinia'
import { useStoreUsuarios, useStoreAssinaturas } from '@stores'
import { helperData } from '@helpers'

const emitter = inject('emitter')
const storeUsuarios = useStoreUsuarios()
const storeAssinaturas = useStoreAssinaturas()

const { mostrarModalAcesso } = storeToRefs(storeUsuarios)

const state = reactive({
  planoSelecionado: '',
  tempoSelecionado: '',
  dataInicioPlano: dataHoje()
})

function fecharModal() {
  storeUsuarios.mostrarModalAcesso = false
  state.planoSelecionado = ''
  state.tempoSelecionado = ''
  state.dataInicioPlano = dataHoje()
}

function dataHoje() {
  const hoje = helperData.hoje()
  const dataFormatada = helperData.formatarDataParaUSA(hoje)
  return dataFormatada
}

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') fecharModal()
})

function selecionarPlano(plano) {
  state.planoSelecionado = plano
}

function selecionarTempo(tempo) {
  state.tempoSelecionado = tempo
}

function ativarAssinatura() {
  const idUsuario = storeUsuarios.modals.idUsuario
  if (!idUsuario) return

  if (!state.planoSelecionado || !state.tempoSelecionado || !state.dataInicioPlano) {
    const mensagem = 'Preencha todos os campos'
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
    return
  }

  const payload = {
    plano: state.tempoSelecionado,
    tipo: state.planoSelecionado,
    dataPagamento: state.dataInicioPlano
  }
  storeAssinaturas.ativarAssinatura(idUsuario, payload)
  fecharModal()
}
</script>

<style scoped>
section.modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: #00000071;
  padding: 30px;
  z-index: 11;
  opacity: 0;
  visibility: visible;
  pointer-events: none;
  transition: all 0.3s;
}

section.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

section.modal.fechar {
  opacity: 0;
  visibility: visible;
  pointer-events: none;
}

.conteudo {
  width: 100%;
  max-height: 100%;
  max-width: 500px;
  background-color: var(--cor-cinza-1);
  border: 2px solid var(--cor-cinza-3);
  border-radius: 10px;
  overflow-y: scroll;
}

.conteudo::-webkit-scrollbar-track {
  background-color: transparent;
}

.conteudo .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  border-bottom: 2px solid var(--cor-cinza-3);
}

.conteudo .titulo .foto {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin: 0 15px 0 0;
}

.conteudo .titulo .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.conteudo .titulo .texto p {
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.conteudo .titulo button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  background: transparent;
  margin-left: auto;
  transition: all 0.5s;
}

.conteudo .titulo button span {
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: var(--cor-vermelho);
  transition: all 0.5s;
}

.conteudo .titulo button span.um {
  transform: rotate(45deg);
}

.conteudo .titulo button span.dois {
  transform: rotate(-45deg);
}

.conteudo .titulo button:hover {
  transform: rotate(90deg);
}

.conteudo .titulo button:hover span {
  background-color: var(--cor-branco);
}

.opcoes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 30px 30px 0 30px;
}

.opcoes .nome {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 15px 0;
}

.opcoes .nome h3 {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.opcoes h3.sites {
  margin-left: auto;
}

.opcoes h3.dominios {
  padding: 0 20px 0 25px;
}

.opcoes button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-cinza-2);
  padding: 15px;
  border-radius: 10px;
  margin: 0 0 10px 0;
  width: 100%;
  transition: all 0.3s;
}

.opcoes button .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.opcoes button .texto h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  text-align: left;
  margin: 0 0 5px 0;
  transition: all 0.3s;
}

.opcoes button .texto p {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  text-align: left;
  transition: all 0.3s;
}

.opcoes button .dados {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 35%;
  margin-left: auto;
}

.opcoes button .dados h4 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  padding: 4px 6px;
  margin: 0 0 0 5px;
  transition: all 0.3s;
}

.opcoes button.ativo .dados h4 {
  color: var(--cor-branco);
  background-color: var(--cor-cinza-4);
}

.opcoes button.ativo p {
  color: var(--cor-branco);
}

.opcoes button img {
  width: 100%;
  max-width: 20px;
  max-height: 20px;
  margin: 0 15px 0 0;
}

.opcoes button.ativo {
  background-color: var(--cor-cinza-3);
}

.opcoes button:hover p {
  color: var(--cor-branco);
}

.tempo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 30px 0 30px;
}

.tempo h3 {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 15px 0;
}

.tempo .itens {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tempo button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-2);
  padding: 15px 0;
  border-radius: 10px;
  width: calc(50% - 5px);
  transition: all 0.3s;
}

.tempo button p {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  text-align: center;
  line-height: 1.4;
  transition: all 0.3s;
}

.tempo button.ativo {
  background-color: var(--cor-cinza-3);
}

.tempo button.ativo p {
  color: var(--cor-branco);
}

.tempo button:hover p {
  color: var(--cor-branco);
}

.data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 30px 30px 0 30px;
}

.data h3 {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 15px 0;
}

.data input[type='date'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 20px;
  font-family: var(--light);
  font-size: var(--f2);
  color: var(--cor-branco);
  opacity: 1;
  background-color: var(--cor-cinza-2);
  transition: all 0.3s;
}

.data input[type='date']::placeholder {
  opacity: 1;
  color: var(--cor-branco);
}

.data input[type='date']:focus {
  background-color: var(--cor-cinza-3);
}

.data input[type='date']::-webkit-datetime-edit {
  color: var(--cor-branco);
}

.botao {
  width: 100%;
  padding: 30px;
}

button.salvar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
  width: 100%;
  padding: 25px 20px;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
}

button.salvar:hover {
  background-color: var(--cor-azul-escuro);
}

button.salvar:disabled {
  color: transparent;
}

button.salvar:disabled span {
  opacity: 1;
  visibility: visible;
}

button.salvar span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--cor-branco);
  border-top: 2px solid transparent;
  box-shadow: 0 5px 30px 0 #ffffff5a;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  animation: girando 0.5s linear infinite;
  pointer-events: none;
  transition: all 0.3s;
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  section.modal {
    align-items: flex-start;
    padding: 10px;
  }

  section.modal .titulo {
    padding: 20px;
  }

  section.modal .botao {
    padding: 20px;
  }

  .opcoes {
    padding: 20px 20px 0 20px;
  }

  .opcoes h3.dominios {
    padding: 0 15px 0 20px;
  }

  .tempo {
    padding: 20px 20px 0 20px;
  }

  .data {
    padding: 20px 20px 0 20px;
  }
}
</style>
