import { api } from './axios'
import { helperToken } from '../../helpers/index'

const idAdministrador = helperToken.idAdministrador()
const token = helperToken.receberToken()

export default {
  receberPerfilAdministrador() {
    if (!idAdministrador) return

    const method = 'GET'
    const url = `perfil/${idAdministrador}`

    return api(method, url, null, token)
  },

  alterarDados(payload) {
    if (!idAdministrador) return

    const method = 'PUT'
    const url = `perfil/${idAdministrador}`

    return api(method, url, payload, token)
  },

  alterarSenha(payload) {
    if (!idAdministrador) return

    const method = 'PATCH'
    const url = `perfil/senha/alterar/${idAdministrador}`

    return api(method, url, payload, token)
  }
}
