import { api } from './axios'
import { helperToken } from '../../helpers/index'

const token = helperToken.receberToken()

export default {
  ativarAssinatura(idUsuario, payload) {
    if (!idUsuario) return

    const method = 'PATCH'
    const url = `assinaturas/ativar/${idUsuario}`

    return api(method, url, payload, token)
  },

  desativarAssinatura(idUsuario) {
    if (!idUsuario) return

    const method = 'PATCH'
    const url = `assinaturas/desativar/${idUsuario}`

    return api(method, url, {}, token)
  }
}
