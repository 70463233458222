<template>
  <section class="lista">
    <div class="topo">
      <div class="total">
        <Svgs nome="cursor" />
        <h3>{{ storePages.pages?.length }} sites Unic</h3>
      </div>
      <div class="input">
        <Svgs class="icone" nome="lupa" />
        <input type="text" spellcheck="false" placeholder="Buscar site" v-model="termoBusca" @input="filtrarPaginas" />
      </div>
    </div>
    <div class="item" v-for="page in paginasFiltradas" :key="page._id">
      <div class="info">
        <div class="nome">
          <div class="dominio">
            <h4 :class="{ ativo: page.dominioPersonalizado }">{{ page.dominioPersonalizado || 'Sem domínio' }}</h4>
            <h3>/{{ page.hotlink }}</h3>
          </div>
          <p>Site de {{ page?.usuario?.email }}</p>
        </div>
      </div>
      <div class="botoes">
        <button class="visualizar" @click="verSite(page.hotlink)">
          <Svgs nome="visualizar" />
          <p>Ver</p>
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, watchEffect, ref } from 'vue'
import { useStorePages } from '../../../../stores/index.js'
import { helperRedirect } from '../../../../helpers/index'
import Svgs from '../../../global/svgs/Svgs.vue'

const storePages = useStorePages()
const termoBusca = ref('')
const paginasFiltradas = ref([])

function carregarPaginas() {
  storePages.receberAtivos()
}

function verSite(hotlink) {
  const url = `https://unic.page/${hotlink}`
  helperRedirect.redirecionar(url)
}

function filtrarPaginas() {
  const termo = termoBusca.value.trim().toLowerCase()
  if (termo === '') {
    paginasFiltradas.value = storePages.pages
  } else {
    paginasFiltradas.value = storePages.pages.filter((page) => {
      return page.dominioPersonalizado?.toLowerCase().includes(termo) || page.hotlink.toLowerCase().includes(termo) || page.usuario.email.toLowerCase().includes(termo)
    })
  }
}

watchEffect(() => {
  paginasFiltradas.value = storePages.pages
})

onMounted(() => {
  carregarPaginas()
})
</script>

<style scoped>
section.lista {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  min-height: 100dvh;
}

.topo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px 0;
}

.topo .total {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 200px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  margin: 0 15px 0 0;
}

.topo .total svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.topo .total h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(50% - 8px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  padding: 20px 10px 20px 20px;
  margin: 0 0 20px 0;
  transition: all 0.3s;
}

.item .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.item .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
}

.item .nome .dominio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item .nome .dominio h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.item .nome .dominio h4 {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  margin: 0 0 5px 0;
}

.item .nome .dominio h4.ativo {
  color: var(--cor-verde);
}

.item .nome p {
  font-family: var(--light);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 5px 0 0 0;
}
.item .botoes {
  display: flex;
  align-items: center;
}
.item .botoes button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-2);
  border-left: 2px solid var(--cor-cinza-3);
  width: 90px;
}
.item .botoes button:hover p {
  color: var(--cor-branco);
}
.item .botoes button:hover svg {
  fill: var(--cor-branco);
}
.item .botoes button p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  margin: 12px 0 0 0;
  transition: all 0.3s;
}
.item .botoes button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza-5);
  transition: all 0.3s;
}
/* Responsivo */
@media screen and (max-width: 1000px) {
  section.lista {
    display: flex;
    flex-direction: column;
  }

  .topo {
    flex-direction: column;
  }

  .topo .total {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    padding: 15px;
    margin: 0 0 20px 0;
  }

  .item {
    width: 100%;
  }

  .item .botoes button {
    width: 60px;
  }
}
</style>
